import localStyles from './aml-quick-check.module.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../context/user/UserContext';
import axios from 'axios';
import { getStaticPath } from '../../services/api/axios';
import { clsx } from 'clsx';
import styles from '../customer-details/style.module.scss';
import { ReactComponent as BoltGreen } from '../../assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as SearchQuickIcon } from '../../assets/images/svg/search-quick.svg';
import MainButton from '../../components/buttons/MainButton';
import Loader from '../../components/loaders/loader/Loader';
import handleApiError from '../../services/ErrorHandleUtil';
import { getChainByAddress } from '../../utils/addresses';
import ConnectedAccounts from '../customer-details/ConnectedAccounts';
import RiskScoreOverview from '../customer-details/RiskScoreOverview';
import TransactionRiskCategories from '../../components/transaction-risk-categories/TransactionRiskCategories';
import { dateStrOrTimestampToTimestamp } from '../../utils/date-formatter';
import NoAccountMessage from '../../components/explorer-like/NoAccountMessage';
import {
  getMisttrackCategories,
  getRiskFromMisttrackCategories
} from '../../utils/misttrack';

const AMLQuickCheck = () => {
  const { explorerProject } = useOutletContext();
  const { getConfig, companyCurrency } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [temporaryJwt, setTemporaryJwt] = useState(undefined);
  const [connectedAddress, setConnectedAddress] = useState('');
  const [hints, setHints] = useState([]);
  const [showHint, setShowHint] = useState(false);
  const [hintsLoading, setHintsLoading] = useState(false);
  const [account, setAccount] = useState();

  // address input box
  const handleAddressChange = (e) => {
    setShowHint(true);
    const newAddress = e.target.value.trim();
    setConnectedAddress(newAddress);
  };

  const handleAutocompleteSelection = (address) => {
    setConnectedAddress(address);
    setShowHint(false);
  };

  // JWT
  const getTemporaryJwt = async (projectId) => {
    try {
      const response = await axios.get(
        getStaticPath('APIAUTH_URL', `project/${projectId}/developer`),
        {
          params: {}
        }
      );
      const jwt = response.data.token;
      setTemporaryJwt(jwt);
      return jwt;
    } catch (error) {
      handleApiError('Getting temporary user JWT', error);
    }
  };

  // address hint
  useEffect(() => {
    if(!explorerProject) {
      return;
    }

    const controller = new AbortController();
    const loadHints = setTimeout(async () => {
      const userJwt = await getTemporaryJwt(explorerProject.id);

      if(connectedAddress.length > 7) {
        try {
          setHintsLoading(true);
          const { data } = await axios.get('/v1/addressname/hint',
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': `Bearer ${userJwt}`,
              },
              params:{
                prefix: connectedAddress
              },
              signal: controller.signal
            }
          );
          setHintsLoading(false);
          setHints(data);
        }
        catch (error) {
          if (error.code !== 'ERR_CANCELED') {
            toast('problem getting hint');
          }
        }
      } else {
        setHints([]);
      }

    }, 300);

    return () => {
      controller.abort();
      clearTimeout(loadHints);
    };
  }, [temporaryJwt, connectedAddress, explorerProject]);

  // risk data
  const getRiskData = async (chain, address, userJwt) => {
    try {
      const response = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          },
          params:{
            chain: chain,
            address: address,
          },
        }
      );
      return response.data;
    } catch (error) {
      handleApiError('Getting risk data', error);
      return 'No data.';
    }
  };

  // connect
  const handleConnect = useCallback(async () => {
    setIsLoading(true);
    const chain = getChainByAddress(connectedAddress)?.ticker;
    const userJwt = await getTemporaryJwt(explorerProject.id);
    const riskData = await getRiskData(chain, connectedAddress, userJwt);
    const balanceData = {
      accounts: [
        {
          account_id: 'any_id',
          description: `${chain.toUpperCase()}-01`,
          wallet: connectedAddress,
          type: 'crypto_wallet',
          balance: (riskData?.balance ?? []).map(balanceEntry => ({
            currency: balanceEntry?.contract_ticker_symbol ?? '?',
            currency_name: balanceEntry?.contract_name ?? '?',
            currency_symbol: balanceEntry?.contract_ticker_symbol ?? '?',
            value: (balanceEntry?.balance ?? 0) / (10 ** (balanceEntry?.contract_decimals ?? 0)),
            converted_currency: 'usd',
            converted_value: balanceEntry?.quote ?? 0,
            image_url: balanceEntry?.logo_urls?.token_logo_url ?? balanceEntry?.logo_url,
          })),
          url: `onchain/${chain}`,
          name: chain.toUpperCase(),
        }
      ]
    };

    const overview = riskData?.overview;
    const nativeBalanceEntry = (riskData.balance ?? []).find(entry => entry?.native_token);
    const exchangeRate = nativeBalanceEntry?.quote_rate ?? 1;

    const highRiskLabels = ['sanctioned', 'malicious', 'mixer', 'high_risk', 'phishing', 'ransom'];
    const riskDetail = riskData?.risk_result?.risk_detail ?? [];
    let highRiskVolume = 0, mediumRiskVolume = 0;
    riskDetail.forEach(entry => {
      if (highRiskLabels.includes(entry?.type)) {
        highRiskVolume += entry?.volume ?? 0;
      } else {
        mediumRiskVolume += entry?.volume ?? 0;
      }
    });
    const totalReceived = exchangeRate === undefined ? '?' : overview?.total_received * exchangeRate ?? 0;
    const totalSent = exchangeRate === undefined ? '?' : overview?.total_spent * exchangeRate ?? 0;
    const totalVolume = totalReceived + totalSent;
    highRiskVolume = Math.min(totalVolume, highRiskVolume);
    mediumRiskVolume = Math.max(0, Math.min(totalVolume - highRiskVolume, mediumRiskVolume));
    const lowRiskVolume = Math.max(0, totalVolume - highRiskVolume - mediumRiskVolume);


    // Uncomment if you want a number of risk txs as well (and also uncomment a snippet a bit below)
    // const { medium: mediumRiskTxs, high: highRiskTxs } = (riskData?.risk_result?.risk_detail ?? []).reduce((acc, entry) => {
    //   const levels = ['medium', 'high'];
    //   for (const level of levels) {
    //     if (String(entry?.type).includes(level)) {
    //       acc[level] += 1;
    //     }
    //   }
    //   return acc;
    // }, { medium: 0, high: 0 });
    // const lowRiskTxs = Math.max(0, (overview?.txs_count ?? 0) - mediumRiskTxs - highRiskTxs);

    // There could be timestamp or date string in response, so we always convert it to timestamp
    const isEth = connectedAddress.startsWith('0x');
    const BTC_GENESIS_TIMESTAMP = 1231502400000;
    const ETH_GENESIS_TIMESTAMP = 1438257600000;
    const minTimestamp = isEth ? ETH_GENESIS_TIMESTAMP : BTC_GENESIS_TIMESTAMP;
    const firstSeen = dateStrOrTimestampToTimestamp(overview?.first_seen, minTimestamp);
    const lastSeen = dateStrOrTimestampToTimestamp(overview?.last_seen, minTimestamp);

    const riskCategories = getMisttrackCategories(riskData?.risk_result ?? {});
    const customRisk = getRiskFromMisttrackCategories(riskCategories);

    const customerDetails = {
      first_tx: firstSeen,
      last_tx: lastSeen,
      age: Math.floor(((new Date()).getTime() / 1000 - firstSeen) / (60 * 60 * 24)),
      total_received: totalReceived,
      total_sent: totalSent,
      total_volume: totalVolume,
      high_risk_volume: highRiskVolume,
      medium_risk_volume: mediumRiskVolume,
      low_risk_volume: lowRiskVolume,
      currency: 'usd',
      // low_risk_tx_count: lowRiskTxs,
      // medium_risk_tx_count: mediumRiskTxs,
      // high_risk_tx_count: highRiskTxs,
      last_location: 'None',
      risk_countries: '',
      risk: customRisk,
      tx_count: overview?.txs_count ?? 0,
      received_txs: overview?.received_txs_count ?? 0,
      sent_txs: overview?.spent_txs_count ?? 0,
      country: 'Unknown',
      non_identified_txs: undefined,
    };

    setAccount({
      chain: chain,
      address: connectedAddress,
      data: riskData,
      balanceData,
      customerDetails,
    });
    setConnectedAddress('');
    setIsLoading(false);
  }, [connectedAddress, account]);

  // disconnect
  const handleDisconnect = () => setAccount(undefined);

  // on first load wait for context
  useEffect(() => {
    if (explorerProject) {
      setIsLoading(false);
    }
  }, [explorerProject]);

  if (isLoading) {
    return <Loader />;
  }

  return <div className='page-content-container'>
    <div className={clsx(styles.customers, 'explorer-container')}>
      <div className='explorer-form'>
        <div className='address-input-outer'>
          <input
            name='addressInput'
            type='text'
            className='address-input-inner'
            placeholder='Paste address to explore account'
            value={connectedAddress}
            onChange={handleAddressChange}
            autoComplete='off'
            onBlur={() => setShowHint(false)}
            onFocus={() => setShowHint(true)}
          />
          {showHint && connectedAddress.length > 7 && (
            <div className='autocomplete-items'>
              {hintsLoading && <div>Loading...</div>}
              {!hintsLoading && hints.map(hint =>
                <div
                  onMouseDown={e => e.preventDefault()} // Prevents input blur
                  onClick={() => handleAutocompleteSelection(hint)}
                  key={hint}
                >
                  {hint}
                </div>
              )}
            </div>
          )}
        </div>
        <MainButton
          variant='button-purple'
          label='CHECK ACCOUNT'
          icon={<BoltGreen />}
          onClick={() => handleConnect()}
          disabled={!connectedAddress}
          extraClasses='connect-button'
        />
      </div>
      {!account && <div className={localStyles.noAccountInfo}>
        <NoAccountMessage
          icon={<SearchQuickIcon />}
          title='Quickly explore accounts'
          description={
            'Please paste address above in the input field to search and explore crypto accounts. ' +
            'Account\'s chain will be automatically detected.'
          }
        />
      </div>}
      {account && <div className={localStyles.tablesContainer}>
        {account?.balanceData && <ConnectedAccounts
          singleAccountMode={true}
          balanceData={account.balanceData}
          reducedInfo
          expandable={true}
          currency={companyCurrency ?? 'USD'}
          canDisconnect={true}
          handleDisconnect={handleDisconnect}
        />}
        {account?.customerDetails && <RiskScoreOverview
          customerDetails={account.customerDetails}
          closedLoop={{
            closed_loop: false,
            external_onchain_incoming: true,
          }}
        />}
        <TransactionRiskCategories riskResult={account?.data?.risk_result} />
      </div>}
    </div>
  </div>;
};

export default AMLQuickCheck;
