import styles from './style.module.scss';
import CustomerDetailsBox from 'pages/customer-details/CustomerDetailsBox';
import { ReactComponent as EarthIcon } from 'assets/images/svg/earth-icon.svg';
import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import ConsolidatedTransactionsLegend from './ConsolidatedTransactionsLegend';
import { clsx } from 'clsx';
import ConsolidatedTransactionsTableRow from './ConsolidatedTransactionsTableRow';
import ConsolidatedTransactionsTableRowConcise from './ConsolidatedTransactionsTableRowConcise';
import ConsolidatedTransactionsButtons from '../../components/consolidated-transactions/ConsolidatedTransactionsButtons';
import ConsolidatedTransactionsFilters from '../../components/filters/ConsolidatedTransactionsFilters';
import { useConfig } from '../../components/config/_ReactConfig';
import { unique, valueExists } from '../../utils/common';
import { toast } from 'react-hot-toast';
import {
  getMisttrackCategoriesByAddress,
  getRiskFromMisttrackCategories, misttrackCategoryToRisk,
} from '../../utils/misttrack';
import { categoryToRiskScore, isLowRisk } from '../../utils/riskLevels';


const TX_TYPES = [
  {
    key: 'crypto deposit',
    label: 'Crypto deposit',
  },
  {
    key: 'crypto withdrawal',
    label: 'Crypto withdrawal',
  },
  {
    key: 'fiat deposit',
    label: 'Fiat deposit',
  },
  {
    key: 'fiat withdrawal',
    label: 'Fiat withdrawal',
  },
  {
    key: 'transfer',
    label: 'Transfer',
  },
];

const DIGITAL_ASSETS = [
  {
    key: 'BTC',
    label: 'Bitcoin',
  },
  {
    key: 'ETH',
    label: 'Ethereum'
  }
];

const TX_RISKS = [
  {
    key: 'low',
    label: 'Low',
  },
  {
    key: 'medium',
    label: 'Medium',
  },
  {
    key: 'high',
    label: 'High',
  },
];

const ConsolidatedTransactions = ({
  userJWT,
  customerName,
  balanceData,
  concise = false,
  currency = 'USD',
  userId,
  projectId,
  withFilters = false,
  setTxsLoading = () => {},
  useMisttrackData = false,
  hiddenFilters = [],
}) => {
  const TXS_PAGE_SIZE = 50;
  const LAST_PAGE_CURSOR = '';

  const { getConfig } = useConfig();
  const [txsData, setTxsData] = useState([]);
  const [txsPageCursor, setTxsPageCursor] = useState();
  const loadedAll = txsPageCursor === LAST_PAGE_CURSOR;
  const [isLoadingTxs, setIsLoadingTxs] = useState(true);
  const [loadingError, setLoadingError] = useState('');
  const [canLoadMoreTxs, setCanLoadMoreTxs] = useState(true);
  const [accountIdToInfoMap, setAccountIdToInfoMap] = useState({});
  const [expandedRowIndex, setExpandedRowIndex] = useState(); // Only for concise table
  const [accountsForFilter, setAccountsForFilter] = useState([]);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [txTypesForFilter, setTxTypesForFilter] = useState(
    TX_TYPES.map(txType => ({ ...txType, checked: false }))
  );
  const [digitalAssetsForFilter, setDigitalAssetsForFilter] = useState(
    DIGITAL_ASSETS.map(asset => ({ ...asset, checked: false }))
  );
  const [txRisksForFilter, setTxRisksForFilter] = useState(
    TX_RISKS.map(txRisk => ({ ...txRisk, checked: false }))
  );
  const [priceForFilter, setPriceForFilter] = useState({ min: '', max: '' });

  const buildFilterParam = (filterArray) => {
    return (filterArray ?? []).filter(option => option.checked).map(option => option.key).join(',');
  };

  const accountsFilterParam = useMemo(
    () => buildFilterParam(accountsForFilter),
    [JSON.stringify(accountsForFilter)]
  );
  const txTypesFilterParam = useMemo(
    () => buildFilterParam(txTypesForFilter),
    [JSON.stringify(txTypesForFilter)]
  );
  const digitalAssetsFilterParam = useMemo(
    () => buildFilterParam(digitalAssetsForFilter),
    [JSON.stringify(digitalAssetsForFilter)]
  );
  const txRisksFilterParam = useMemo(
    () => buildFilterParam(txRisksForFilter),
    [JSON.stringify(txRisksForFilter)]
  );
  const minPriceFilterParam = valueExists(priceForFilter.min) ? priceForFilter.min : undefined;
  const maxPriceFilterParam = valueExists(priceForFilter.max) ? priceForFilter.max : undefined;

  useEffect(() => {
    if (userJWT !== undefined && !initialLoadDone) {
      loadNextTxsPage();
      setInitialLoadDone(true);
    }
  }, [userJWT]);

  useEffect(() => {
    if (balanceData !== undefined) {
      const idToInfo = {};
      if (balanceData.accounts) {
        const initialAccountsCheckboxes = balanceData.accounts.map(account => ({
          key: account.account_id,
          label: account.description,
          checked: false
        }));
        setAccountsForFilter(initialAccountsCheckboxes);
        balanceData.accounts.forEach(account => {
          idToInfo[account.account_id] = {
            address: account.wallet,
            description: account.description,
            provider:  account.url.split('/')[1],
            providerType: account.url.split('/')[0]
          };
        });
      }
      setAccountIdToInfoMap(idToInfo);
    } else {
      setAccountIdToInfoMap({});
    }
  }, [balanceData]);

  const handleApplyFilters = () => {
    setTxsData([]);
    setTxsPageCursor(null);
    setIsLoadingTxs(true);
    setCanLoadMoreTxs(true);
    loadNextTxsPage(null).then(() => setIsLoadingTxs(false));
  };

  const loadNextTxsPage = async (cursor = txsPageCursor) => {
    if (cursor === LAST_PAGE_CURSOR || !userJWT) {
      return;
    }

    setLoadingError('');
    setIsLoadingTxs(true);
    let response;
    const params = {
      page_size: TXS_PAGE_SIZE,
      cursor: cursor
    };
    if (valueExists(accountsFilterParam)) {
      params['account-filter'] = accountsFilterParam;
    }
    if (valueExists(digitalAssetsFilterParam)) {
      params.currencies = digitalAssetsFilterParam;
    }
    if (valueExists(txTypesFilterParam)) {
      params.types = txTypesFilterParam;
    }
    if (valueExists(txRisksFilterParam)) {
      params.risks = txRisksFilterParam;
    }
    if (valueExists(minPriceFilterParam)) {
      params['min-price'] = minPriceFilterParam;
    }
    if (valueExists(maxPriceFilterParam)) {
      params['max-price'] = maxPriceFilterParam;
    }

    setTxsLoading(true);
    try {
      response = await axios.get(`/v1/aggregate/transactions?currency=${currency}&risk2=1`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': 'Bearer ' + userJWT,
          },
          params
        });
    } catch (error) {
      if (error?.response?.status === 504) {
        setLoadingError('Request is addressing too much data. Try reducing filters usage.');
        toast.error('Request is addressing too much data. Try reducing filters usage.');
      } else {
        setLoadingError('An error occured. Please try loading more transactions or refresh the page.');
        if (error?.response?.data?.detail) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(`An error occured. Please try loading more transactions or refresh the page. ${error?.message}`);
        }
      }
      setIsLoadingTxs(false);
      return;
    }
    setTxsLoading(false);

    const { transactions, page_cursor, risk2: misttrackRiskInfo } = response?.data || {};

    if (transactions && page_cursor !== null) {
      setTxsPageCursor(page_cursor);

      if (!useMisttrackData) {
        setTxsData(prev => [...prev, ...transactions]);
      } else {
        const mergedMisttrackRiskDetail = Object.values(misttrackRiskInfo ?? {}).reduce((acc, singleAccountInfo) => {
          const thisAccountRiskDetail = singleAccountInfo?.risk_result?.risk_detail ?? [];
          return [...acc, ...thisAccountRiskDetail];
        }, []);
        const misttrackRiskLabelsByAddress = getMisttrackCategoriesByAddress(mergedMisttrackRiskDetail);
        const txsWithCustomRisk = transactions.map(tx => {
          const involvedAddresses = [...tx.inputs, ...tx.outputs]
            .map(movement => movement?.address)
            .filter(address => valueExists(address))
            .map(address => address.toLowerCase());
          const involvedMisttrackCategories = unique(
            involvedAddresses
              .map(address => misttrackRiskLabelsByAddress?.[address])
              .filter(labels => labels)
              .flat()
          );
          const riskFromLabels = getRiskFromMisttrackCategories(involvedMisttrackCategories);

          const transformMovement = (movement) => {
            const address = (movement?.address ?? '').toLowerCase();
            const categories = misttrackRiskLabelsByAddress?.[address] ?? [];
            categories.sort((a, b) => {
              const aRisk = (misttrackCategoryToRisk?.[b] ?? 0);
              const bRisk = (misttrackCategoryToRisk?.[a] ?? 0);
              return aRisk > bRisk ? -1 : 1;
            });
            const movementIsLowRisk = isLowRisk(movement?.risk ?? 100);
            const misttrackRisk = getRiskFromMisttrackCategories(categories);
            const combinedRisk = movementIsLowRisk ? Math.max(movement.risk, misttrackRisk) : misttrackRisk;

            const bmLowRiskCategories = movement.categories.filter(category => isLowRisk(categoryToRiskScore(category)));
            categories.push(...bmLowRiskCategories);

            return {
              ...movement,
              category: categories?.[0] ?? '',
              categories,
              risk: combinedRisk,
            };
          };

          const inputsWithCustomCategories = (tx?.inputs ?? []).map(transformMovement);
          const outputsWithCustomCategories = (tx?.outputs ?? []).map(transformMovement);

          return {
            ...tx,
            inputs: inputsWithCustomCategories,
            outputs: outputsWithCustomCategories,
            risk: riskFromLabels,
          };
        });
        setTxsData(prev => [...prev, ...txsWithCustomRisk]);
      }

      if (transactions.length < TXS_PAGE_SIZE) {
        setCanLoadMoreTxs(false);
      }
    }
    setIsLoadingTxs(false);
  };

  return (
    <CustomerDetailsBox
      title='Consolidated transactions'
      icon={<EarthIcon/>}
      legend={!concise && <ConsolidatedTransactionsLegend />}
      buttons={concise && <ConsolidatedTransactionsButtons userId={userId} projectId={projectId} userJWT={userJWT} />}
      concise={concise}
    >
      {withFilters && <ConsolidatedTransactionsFilters
        accounts={accountsForFilter}
        setAccounts={setAccountsForFilter}
        types={txTypesForFilter}
        setTypes={setTxTypesForFilter}
        assets={digitalAssetsForFilter}
        setAssets={setDigitalAssetsForFilter}
        risks={txRisksForFilter}
        setRisks={setTxRisksForFilter}
        price={priceForFilter}
        setPrice={setPriceForFilter}
        handleApplyFilters={handleApplyFilters}
        disabled={isLoadingTxs}
        currency={currency}
        hiddenFilters={hiddenFilters}
      />}
      {concise
        ? <div className={styles.consolidatedTransactions}>
          <div className={clsx(styles.tableHeader, styles.tableHeaderConcise)}>
            <span></span>
            <span>Date and Time</span>
            <span>Account</span>
            <span>Type</span>
            <span>Transfer</span>
            <span>Sum</span>
            <span>Risk score</span>
          </div>
          <div className={styles.tableBody}>
            {txsData.map((transaction, n) => (
              <div key={n}>
                <ConsolidatedTransactionsTableRowConcise
                  data={transaction}
                  accountInfo={accountIdToInfoMap[transaction.account_id]}
                  ownMovementsLabel={customerName}
                  otherRowIsExpanded={expandedRowIndex !== n}
                  onExpand={() => setExpandedRowIndex(n)}
                  currency={currency}
                  riskLabelsOnly={useMisttrackData}
                />
                <div className={styles.rowDivider}></div>
              </div>
            ))}
            {loadingError && <div className={styles.infoMessageContainer}>
              <div className={styles.infoMessage}>
                {loadingError}
              </div>
            </div>}
            {(txsData ?? []).length === 0 && !isLoadingTxs && !loadingError && <div className={styles.infoMessageContainer}>
              <div className={styles.infoMessage}>
                There are no transactions to display.
              </div>
            </div>}
          </div>
        </div>
        : <div className={styles.consolidatedTransactions}>
          <div className={styles.tableHeader}>
            <span>Date and Time</span>
            <span>Tx hash</span>
            <span>Type</span>
            <span>From</span>
            <span>To</span>
            <span>Risk Score</span>
            <span>Tx Category</span>
          </div>
          <div className={styles.tableBody}>
            {txsData.map((transaction, n) => (
              <ConsolidatedTransactionsTableRow
                key={n}
                data={transaction}
                ownMovementsLabel={customerName}
                accountInfo={accountIdToInfoMap[transaction.account_id]}
              />
            ))}
          </div>
        </div>
      }
      {!loadedAll && <div className={styles.loadMoreContainer} >
        {isLoadingTxs && <span className={styles.loadingText}>Loading...</span>}
        {!isLoadingTxs && canLoadMoreTxs &&
          <span className={styles.loadMoreText} onClick={() => loadNextTxsPage()}>
            Load more
          </span>
        }
      </div>}
    </CustomerDetailsBox>
  );
};

export default ConsolidatedTransactions;
