import styles from '../customer-details/style.module.scss';
import { useOutletContext } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import handleApiError from '../../services/ErrorHandleUtil';
import { getStaticPath } from '../../services/api/axios';
import { UserContext } from '../../context/user/UserContext';
import MainButton from '../../components/buttons/MainButton';
import { ReactComponent as BoltGreen } from '../../assets/images/svg/boltGreenIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/svg/search.svg';
import { ReactComponent as RefreshIcon } from '../../assets/images/svg/refresh.svg';
import Loader from '../../components/loaders/loader/Loader';
import ConnectedAccounts from '../customer-details/ConnectedAccounts';
import ConsolidatedTransactions from '../customer-details/ConsolidatedTransactions';
import { padWithZeros, valueExists } from '../../utils/common';
import { toast } from 'react-hot-toast';
import { OLD_TABLES_KEY } from '../debug/debug';
import { clsx } from 'clsx';
import jwt_decode from 'jwt-decode';
import { getChainByAddress } from '../../utils/addresses';
import NoAccountMessage from '../../components/explorer-like/NoAccountMessage';
import { filterTypes } from '../../components/filters/ConsolidatedTransactionsFilters';

const RefreshButton = ({ onClick }) => {
  return <div className='refresh-button' onClick={onClick}>
    <RefreshIcon className='icon' />
    <div className='refresh-text'>
      Refresh data
    </div>
  </div>;
};

const Explorer = () => {
  const SUPPORTED_CHAINS = [
    {
      label: 'Bitcoin',
      ticker: 'btc',
      providerUrl: 'onchain/btc'
    },
    {
      label: 'Ethereum',
      ticker: 'eth',
      providerUrl: 'onchain/eth'
    }
  ];

  const { explorerProject } = useOutletContext();
  const { userEmail, getConfig, companyCurrency } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [temporaryUserName, setTemporaryUserName] = useState();
  const [balanceData, setBalanceData] = useState();
  const [temporaryUserJwt, setTemporaryUserJwt] = useState(undefined);
  const [temporaryUserId, setTemporaryUserId] = useState(undefined);
  const [refreshTxsKey, setRefreshTxsKey] = useState(0);
  const [hints, setHints] = useState([]);
  const [showHint, setShowHint] = useState(false);
  const [hintsLoading, setHintsLoading] = useState(false);
  const oldTables = localStorage.getItem(OLD_TABLES_KEY) === 'ON';
  const [txsLoading, setTxsLoading] = useState(false);

  const [connectedAddress, setConnectedAddress] = useState('');
  const [nextAccountIdByChain, setNextAccountIdByChain] = useState(
    Object.fromEntries(SUPPORTED_CHAINS.map(chainInfo => [chainInfo?.ticker, 1]))
  );

  const getTemporaryUserName = () => {
    if (temporaryUserName) {
      return temporaryUserName;
    }
    const userName = `${userEmail}-${Date.now()}`;
    setTemporaryUserName(userName);
    return userName;
  };

  const getTemporaryUserJwt = async (projectId, userName) => {
    try {
      const response = await axios.get(
        getStaticPath('APIAUTH_URL', `user/${projectId}/cluid`),
        {
          params: {
            cluid: encodeURIComponent(userName)
          }
        }
      );
      const jwt = response.data.token;
      setTemporaryUserJwt(jwt);
      const decoded = jwt_decode(jwt);
      setTemporaryUserId(decoded?.user_uuid);
      return jwt;
    } catch (error) {
      handleApiError('Getting temporary user JWT', error);
    }
  };

  const connectAddress = async (projectId, chain, address, description) => {
    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(projectId, userName);
    const chainInfo = SUPPORTED_CHAINS.find(providerInfo => providerInfo?.ticker === chain);
    if (!chainInfo) {
      console.error(`Chain ${JSON.stringify(chain)} is not supported.`);
    }
    try {
      await axios.post(
        getStaticPath('ACCOUNT_URL', 'connect'),
        {},
        {
          baseUrl: getConfig('api_url'),
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${userJwt}`,
            'content-type': 'application/json'
          },
          params: {
            user_jwt: userJwt,
            chain: chain.toLowerCase(),
            wallet: address,
            description: description ?? address
          }
        }
      );
      await fetchBalance(userJwt);
      setRefreshTxsKey(prevRefreshTxsKey => 1 - prevRefreshTxsKey);
      return true;
    } catch (error) {
      if (error?.response?.data?.detail) {
        toast.error(error.response.data.detail);
      }
      handleApiError('Connecting address', error, false);
    }
    return false;
  };

  const fetchBalance = async (userJwt) => {
    try {
      let response = await axios.get(`/v1/aggregate/balance?currency=${companyCurrency ?? 'USD'}`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          }
        });
      setBalanceData(response.data);
      if ((response?.data?.accounts ?? []).length === 0) {
        setBalanceData(undefined);
      }
    } catch (error) {
      setBalanceData(undefined);
      handleApiError('Getting balance', error);
    }
  };

  const getProviderByAddress = (address) => {
    const chainCandidate = getChainByAddress(address);
    const supportedChainCandidate =
      SUPPORTED_CHAINS.find(chainInfo => chainInfo?.ticker === chainCandidate?.ticker);
    let errorMessage = '';
    if (!valueExists(supportedChainCandidate)) {
      const supportedChainsList = SUPPORTED_CHAINS.length === 1 ?
        SUPPORTED_CHAINS[0].label :
        `${SUPPORTED_CHAINS.slice(0, -1).map(chainInfo => chainInfo.label).join(', ')} and ${SUPPORTED_CHAINS.slice(-1)[0].label}`;
      const providedAddressInfo = chainCandidate ?
        `The provided address probably belongs to ${chainCandidate.label}.` :
        '';
      errorMessage = `Unsupported address format. Only ${supportedChainsList} are supported. ${providedAddressInfo}`;
    }
    return {
      provider: supportedChainCandidate,
      errorMessage: valueExists(supportedChainCandidate) ? '' : errorMessage
    };
  };

  const handleConnect = () => {
    const { provider, errorMessage } = getProviderByAddress(connectedAddress);
    if (valueExists(errorMessage)) {
      toast.error(errorMessage);
      return;
    }

    const chainTicker = provider?.ticker;
    const accountId = nextAccountIdByChain[chainTicker];
    const description = `${chainTicker.toUpperCase()}-${padWithZeros(accountId, 2)}`;
    connectAddress(explorerProject.id, chainTicker, connectedAddress, description)
      .then(wasSuccessful => {
        if (wasSuccessful) {
          setNextAccountIdByChain(prevNextAccountIdByChain => ({
            ...prevNextAccountIdByChain,
            [chainTicker]: accountId + 1
          }));
          setConnectedAddress('');
        }
      });
  };

  const handleDisconnect = async (accountId) => {
    const accountUrl = (balanceData?.accounts ?? []).find(account => account.account_id === accountId)?.url;
    if (!accountUrl) {
      return;
    }
    const userName = getTemporaryUserName();
    const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
    try {
      await axios.delete(
        `/v1/${accountUrl}`,
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          }
        }
      );
      await fetchBalance(userJwt);
      setRefreshTxsKey(prevRefreshTxsKey => 1 - prevRefreshTxsKey);
    } catch (error) {
      handleApiError('Disconnecting account', error);
    }
  };

  const handleAddressChange = (e) => {
    setShowHint(true);
    const newAddress = e.target.value.trim();
    setConnectedAddress(newAddress);
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      const userName = getTemporaryUserName();
      const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);
      await fetchBalance(userJwt);
      setIsRefreshing(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (explorerProject) {
      setIsLoading(false);
    }
  }, [explorerProject]);

  useEffect(() => {
    if(!explorerProject) {
      return;
    }

    const controller = new AbortController();
    const loadHints = setTimeout(async () => {
      const userName = getTemporaryUserName();
      const userJwt = await getTemporaryUserJwt(explorerProject.id, userName);

      if(connectedAddress.length > 7) {
        try {
          setHintsLoading(true);
          const { data } = await axios.get('/v1/addressname/hint',
            {
              baseURL: getConfig('api_url'),
              headers: {
                'Authorization': `Bearer ${userJwt}`,
              },
              params:{
                prefix: connectedAddress
              },
              signal: controller.signal
            }
          );
          setHintsLoading(false);
          setHints(data);
        }
        catch (error) {
          if (error.code !== 'ERR_CANCELED') {
            toast('problem getting hint');
          }
        }
      } else {
        setHints([]);
      }

    }, 300);

    return () => {
      controller.abort();
      clearTimeout(loadHints);
    };
  }, [temporaryUserJwt, connectedAddress, explorerProject, temporaryUserName]);

  const handleAutocompleteSelection = (address) => {
    setConnectedAddress(address);
    setShowHint(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return <div className='page-content-container'>
    <div className={clsx(styles.customers, 'explorer-container')}>
      <div className='explorer-form'>
        <div className='address-input-outer'>
          <input
            name='addressInput'
            type='text'
            className='address-input-inner'
            placeholder='Paste address to explore account'
            value={connectedAddress}
            onChange={handleAddressChange}
            autoComplete='off'
            onBlur={() => setShowHint(false)}
            onFocus={() => setShowHint(true)}
          />
          {showHint && connectedAddress.length > 7 && (
            <div className='autocomplete-items'>
              {hintsLoading && <div>Loading...</div>}
              {!hintsLoading && hints.map(hint =>
                <div
                  onMouseDown={e => e.preventDefault()} // Prevents input blur
                  onClick={() => handleAutocompleteSelection(hint)}
                  key={hint}
                >
                  {hint}
                </div>
              )}
            </div>
          )}
        </div>
        <MainButton
          variant='button-purple'
          label={balanceData ? 'ADD ACCOUNT' : 'EXPLORE ACCOUNT'}
          icon={<BoltGreen/>}
          onClick={() => handleConnect()}
          disabled={!connectedAddress}
          extraClasses='connect-button'
        />
      </div>

      {!balanceData &&
        <div className='no-accounts-info'>
          <NoAccountMessage
            icon={<SearchIcon />}
            title='Explore accounts'
            description={
              'Please paste address above in the input field to search and explore crypto accounts. ' +
              'Account\'s chain will be automatically detected.'
            }
          />
        </div>
      }

      {balanceData && <ConnectedAccounts
        balanceData={balanceData}
        reducedInfo
        button={isRefreshing ? <Loader relative /> : <RefreshButton onClick={handleRefresh} />}
        expandable={!oldTables}
        currency={companyCurrency ?? 'USD'}
        canDisconnect={!txsLoading}
        handleDisconnect={handleDisconnect}
      />}
      {balanceData && temporaryUserJwt && <ConsolidatedTransactions
        key={refreshTxsKey}
        customerName='explored-account'
        balanceData={balanceData}
        userJWT={temporaryUserJwt}
        userId={temporaryUserId}
        projectId={explorerProject?.id}
        concise={!oldTables}
        currency={companyCurrency ?? 'USD'}
        withFilters
        setTxsLoading={setTxsLoading}
        useMisttrackData
        hiddenFilters={[filterTypes.RISKS]}
      />}

    </div>
  </div>;
};

export default Explorer;
